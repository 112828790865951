/* eslint-disable */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

import {
  Typography,
  VariableContent,
  useMapi,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'
import { Form } from '@leshen/ui'
import { Layout } from '@leshen/gatsby-theme-contentful'
const { EmilyClient } = require('@clearlink/emily')
import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-aet-form']
  const { requestId, sessionPromoCode, rotatedNumber } = useMapi()
  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false)

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Aetna Medicare Solutions',
          description: 'Aetna Medicare Solutions',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/form',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Aetna Medicare Solutions',
    defaultRobots: 'follow,index',

    main: (
      <>
        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h1">
                Request A Complimentary Quote<sup>*</sup>
              </Typography>
              <Typography variant="legal">
                <sup>*</sup>You are not obligated to enroll and current or
                future Medicare enrollment status will not be impacted.
                Automatic enrollment will not occur.
              </Typography>
              <br />
              <br />
              <div className="form-page-form">
                <Form
                  customSubmissionMessage={
                    <>
                      <Typography variant="">
                        Thank you for submitting your Scope of Appointment
                        Request. As per CMS 2024 Final Rule regulation, you can
                        expect our return call in 2 business days. If you are
                        available to speak now call{' '}
                        <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>
                      </Typography>
                      <Typography variant="">
                        Or browse Aetna Plans available in your area online 24/7
                        at the button below.
                      </Typography>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <LinkButton
                          variant="feature"
                          to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/"
                          color="primary"
                          className=""
                          requestId={requestId}
                        >
                          Browse Plans Now
                        </LinkButton>
                      </div>
                    </>
                  }
                  formSource="eloqua"
                  submitButtonText="Submit"
                  submitButtonColor="primary"
                  submitButtonOutlined={false}
                  submitButtonGradient={false}
                  inlineButton={false}
                  onSubmit={async ({
                    values,
                    setShowSubmissionMessage,
                    setShowSubmissionErrorMessage,
                  }) => {
                    const mergedValues = {
                      ...values,
                      brand: 'AET',
                      elqForm: 'med-lead-form-page',
                      origin: 'https://www.aetnamedicaredirect.com',
                      domain: 'https://www.aetnamedicaredirect.com',
                      promo_code: sessionPromoCode || 88487,
                      request_id: requestId,
                      source: 'med-lead-form-page',
                    }
                    try {
                      const emilyClient = new EmilyClient(
                        'https://leads.clear.link'
                      )
                      // const emilyClient = new EmilyClient(
                      //   'https://leads.dev.aws.clear.link'
                      // )

                      const response = await emilyClient.submit(mergedValues)

                      if (response.status === 200) {
                        setShowSubmissionMessage(true) // Setting showSubmissionMessage to true here
                      } else {
                        setShowSubmissionMessage(false)
                      }
                    } catch (error) {
                      // If an error occurs:
                      console.error('Submission error:', error)
                      setShowSubmissionMessage(false)
                      setShowSubmissionErrorMessage(true)
                    }
                  }}
                  emilyTCPAUpdate={() => {}}
                  steps={null}
                  showSubmissionMessage={showSubmissionMessage}
                  fields={[
                    {
                      name: 'first_name',
                      type: 'text',
                      label: 'First Name',
                      required: true,
                    },
                    {
                      name: 'last_name',
                      type: 'text',
                      label: 'Last Name',
                      required: true,
                    },
                    {
                      name: 'phone_number',
                      type: 'tel',
                      label: 'Phone Number',
                      required: true,
                    },
                    {
                      name: 'zip_postal',
                      type: 'text',
                      label: 'Zip Code',
                      required: true,
                    },
                    {
                      name: 'interested_in',
                      type: 'checkbox',
                      required: true,
                      label:
                        'Please select which Medicare plan types you’re interested in:',
                      options: [
                        {
                          name: 'Medicare Advantage',
                          value: 'medicareAdvantage',
                          displayName: 'Medicare Advantage',
                          label: 'Medicare Advantage',
                        },
                        {
                          name: 'Prescription Drug/Part D',
                          value: 'prescriptionDrugPartD',
                          displayName: 'Prescription Drug/Part D',
                          label: 'Prescription Drug/Part D',
                        },
                        {
                          name: 'Hearing Coverage',
                          value: 'hearingCoverage',
                          displayName: 'Hearing Coverage',
                          label: 'Hearing Coverage',
                        },
                        {
                          name: 'Vision Coverage',
                          value: 'visionCoverage',
                          displayName: 'Vision Coverage',
                          label: 'Vision Coverage',
                        },
                        {
                          name: 'Dental Coverage',
                          value: 'dentalCoverage',
                          displayName: 'Dental Coverage',
                          label: 'Dental Coverage',
                        },
                        {
                          name: 'Medicare Supplement/Medigap',
                          value: 'medicareSupplementMedigap',
                          displayName: 'Medicare Supplement/Medigap',
                          label: 'Medicare Supplement/Medigap',
                        },
                      ],
                    },
                  ]}
                  isMultiStep={false}
                  tcpaCheckbox={false}
                  initialStepValues={{}}
                  spanish={false}
                />
              </div>
            </>
          }
        ></VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
